
import { ISimpleDataItem } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IManipulationType } from '../types';

@Component
export default class ManipulationDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly manipulation: IManipulationType | undefined;

  @Prop({ default: () => [] })
  readonly groups: Array<ISimpleDataItem> | undefined;

  item: IManipulationType | null = null;

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.manipulation);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    for (let i = 0; i < this.groups!.length; i++) {
      if (this.groups![i].id === this.item!.group) {
        this.item!.groupName = this.groups![i].name;
        break;
      }
    }
    this.$emit('store', this.item)
  }

  switchActive() {
    if (this.item) {
      this.item.active = !this.item.active
      this.$emit('store', this.item)
    }
  }

  remove() {
    this.$emit('remove', this.item)
  }
}
